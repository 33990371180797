import React from 'react';

import welcomeImage from '../../../../assets/welcome.png'

import './Welcome.css';

interface WelcomeProps {
  startGame: () => void;
}

export function Welcome({startGame}: WelcomeProps) {
  return <div className="welcome-body">
      <div className="welcome-container">
        <img src={welcomeImage} alt="Happy Birthday" />
        <button onClick={startGame}>Play</button>
        <p>Happy Birthday Madison! Another year gone by and you're already 5! It's hard to believe just how much you've grown in a year and we are so excited to see you start kindergarten in the fall! Just like last year we've put some money away for you later and it is now worth $895!</p>
        <p>We hope you have a lot of fun today and at your party. We created a matching game for you to play.</p>
        <p>Lots of Love, Tyler and Matt.</p>
      </div>
    </div>;
}
