import React, {useState} from 'react';
import Confetti from 'react-confetti';
import { Board, Controls, Welcome } from './components';

import './Game.css';
import { useGameContext } from './GameProvider';

export interface GameProps {}

export function Game() {
  const {newGame, winner, bestScore, attempts, size} = useGameContext();
  const [welcome, setWelcome] = useState(true);

  const startGame = () => {
    setWelcome(false);
    newGame(6);
  }

  if(welcome){
    return <Welcome startGame={startGame}/>
  }

  const confettiMarkup = winner ? <Confetti/> : <></>
  const gameClass = `game game--${size}`

  return <div className={gameClass}>
      { winner && <Controls />}
      <div className="score">
        <div className='key'>Current Score</div><div className='value'>{Math.floor(attempts)}</div>
        {bestScore && <><div className='key'>Best Score</div><div className='value'>{bestScore}</div></>}
      </div>
      <Board/>
      <h1>Madison's Matchup</h1>
      {confettiMarkup}
      <Controls/>
    </div>
}

