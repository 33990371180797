import React from 'react';
import { useGameContext } from '../../GameProvider';

import './CardView.css';
import balloons from '../../../../assets/balloons.png';

export interface CardViewProps {
  cardIndex: string
}

export function CardView({cardIndex}: CardViewProps) {
  const {cards, flipCard} = useGameContext();
  const card = cards[cardIndex];
  const classes = ['card'];
  if(card.flipped) classes.push('card--flipped');
  if(card.matched) classes.push('card--matched');
  const className = classes.join(' ');
  const cardFrontClass = `card-front ${card.pair ? 'card-front--odd' : ''}`
  const cardBackClass = `card-back ${card.matched ? 'card-back--matched' : 'card-back--unmatched'}`

  return <div className={className} onClick={()=>{flipCard(card.index)}}>
    <div className="card-inner">
      <div className={cardBackClass}>
        <img src={card.src} alt="ballons" />
      </div>
      <div className={cardFrontClass}>
        <img src={balloons} alt="ballons" />
      </div>
    </div>
  </div>
}
